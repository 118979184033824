const blocks = document.querySelectorAll('.block')

blocks.forEach(block => {
  block.classList.remove('block-transition')
  
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        block.classList.add('block-transition')
        observer.unobserve(block)
      } else {
        block.classList.remove('block-transition')
      }
    })
  })
  
  observer.observe(block)
})